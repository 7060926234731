
export default {
  props: {
    block: {
      type: Array,
      default: () => [],
    },
    textColour: {
      type: String,
      default: ``,
    },
    background: {
      type: [String, Boolean],
      default: false,
    },
  },
  data () {
    return {
      shopPosts: null,
    };
  },
  computed: {
    membershipField () {
      const membership = this.currentMembership;
      return membership ? `aberdna_${membership}` : `standard`;
    },
    shopItems () {
      if (this.shopPosts) {
        return this.shopPosts.map(item => this.getItemFields(item));
      }
      return [];
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.getShopPosts();
    });
  },
  methods: {
    async getShopPosts () {
      const queryParams = {
        fields: `id,title,categories,slug,type,acf`,
        posts: {
          post_type: `afc_shop`,
          post_status: `publish`,
          post__in: this.block.map(item => item.shop_item.ID),
          orderby: `post__in`,
          order: `DESC`,
        },
        options: {
          image_size: `medium_large`,
        },
      };

      const request = await this.$getPosts(`/posts`, queryParams);
      if (request !== null) {
        this.shopPosts = request;
      }
    },
    getItemFields (shopItem) {
      const defaultFields = shopItem.acf.standard;
      const membershipFields = shopItem.acf[`aberdna_${this.currentMembership}`];

      // If an ad exists for this membership field exists, output that, otherwise output the default ad
      return this.membershipFieldsExist(membershipFields) ? membershipFields : defaultFields;
    },
    membershipFieldsExist (fields) {
      return fields && fields?.shop_title && fields?.shop_image;
    },
  },
};
